// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-opt-in-js": () => import("./../../../src/templates/opt-in.js" /* webpackChunkName: "component---src-templates-opt-in-js" */),
  "component---src-templates-posts-blog-js": () => import("./../../../src/templates/posts/blog.js" /* webpackChunkName: "component---src-templates-posts-blog-js" */),
  "component---src-templates-prices-js": () => import("./../../../src/templates/prices.js" /* webpackChunkName: "component---src-templates-prices-js" */),
  "component---src-templates-succesverhalen-js": () => import("./../../../src/templates/succesverhalen.js" /* webpackChunkName: "component---src-templates-succesverhalen-js" */)
}

